import "slick-carousel";

const $win = $(window);
const $doc = $(document);

// Your code goes here...
// jQuery.ready is no longer needed

slider();

sliderFeatured();

// mobileDropdownClick();

expandSection();

navTrigger();

stickyNavTrigger();

addScrollOnLinks();

addOnPageLoadScroll();

function navTrigger() {
	$(".nav-trigger, .nav__close").on("click", function (e) {
		$(".nav").toggleClass("active");
	});
}

// function mobileDropdownClick() {
// 	$('.nav .menu-item-has-children > a').one('click', !isMobile() );
// }

// function isMobile() {
// 	return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// }

function handleNavMenuClick(event) {
	const nav = document.querySelector(".nav");
	const navItem = event.currentTarget.parentNode;

	if (!nav || !navItem || navItem.classList.contains("menu-item-has-children")) return;

	nav.classList.remove("active");
}

function headerMenuHandler() {
	const headerMenuLinks = document.querySelectorAll("#menu-header-menu .menu-item > a");

	if (!headerMenuLinks) return;

	headerMenuLinks.forEach((link) => link.addEventListener("click", handleNavMenuClick));
}

headerMenuHandler();

function expandSection() {
	$(".section-event .link-more").on("click", function (e) {
		const $this = $(this);
		let text = $this.text();
		$this
			.text(text === "Read More" ? "Close" : "Read More")
			.prev(".section__entry")
			.toggleClass("section__entry--expanded");
		e.preventDefault();
	});
}

function slider() {
	$(".slider .slider__slides").slick({
		dots: false,
		arrows: true,
		infinite: true,
		autoplay: true,
	});
}

function sliderFeatured() {
	$(".slider-featured .slider__slides").slick({
		dots: false,
		arrows: false,
		infinite: true,
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 1229,
				settings: {
					slidesToShow: 3,
					arrows: true,
				},
			},
			{
				breakpoint: 968,
				settings: {
					slidesToShow: 1,
					arrows: true,
				},
			},
		],
	});
}

function stickyNavTrigger() {
	const $header = $(".header");

	$win.on("scroll", function () {
		//Sticky Header
		$(".nav-trigger").toggleClass(
			"nav-trigger--fixed",
			$win.scrollTop() > $header.outerHeight() + $header.offset().top
		);
	});
}

function addScrollOnLinks() {
	$(".scroll-link a").on("click", function (e) {
		let anchorLink = $(this).prop("href");
		if (anchorLink.indexOf("#") === -1) {
			return;
		}

		if ($(this).parent().hasClass("active")) {
			e.preventDefault();
		}

		let adminHeight = 0;

		if ($("#wpadminbar").length) {
			adminHeight = $("#wpadminbar").height();
		}

		let anchorLinkParts = anchorLink.split("#");
		let sectionId = anchorLinkParts[anchorLinkParts.length - 1];
		let $section = $('[data-id="' + sectionId + '"]');
		if ($section.length) {
			$("html, body").animate({
				scrollTop: $section.offset().top - adminHeight,
			});
		}
	});
}

function addOnPageLoadScroll() {
	if (window.location.hash) {
		var hash = window.location.hash.substring(1);
		let adminHeight = 0;

		if ($("#wpadminbar").length) {
			adminHeight = $("#wpadminbar").height();
		}

		let $targetElement = $('[data-id="' + hash + '"]');

		if (!$targetElement.length) {
			return;
		}

		let offsetHash = $targetElement.offset().top - adminHeight;

		$("body, html").animate(
			{
				scrollTop: offsetHash,
			},
			500
		);
	}
}
